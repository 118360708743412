import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import fmsProjMaster from '../../../fms/unitInventory/fmsProject';
import addFmsGenerateBatch from './addFmsGenerateBatch';
import addFmsGenerateBatchBill from './addFmsGenerateBatchBill';
import URL_UTILITY from '../../../../utility/url.utility';
import { format } from 'date-fns';
export default {
  name: 'fmsGenerateBatchBill',
  components: {
    ModelSelect,
    DatePicker,
    fmsProjMaster,
    addFmsGenerateBatch,
    addFmsGenerateBatchBill
  },
  watch: {
    currentPage: function() {
      this.getFmsGenBatchBillList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getFmsGenBatchBillList();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    }
  },
  data() {
    return {
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      selectedBatch: null,
      showBillModal: false,
      selectAllCheckBox: false,
      showAdvanceSearch: false,
      batchBillId: null,
      showBatchBillModal: false,
      showFmsPrjModal: false,
      payload: {},
      showIntRateModal: false,
      loader: false,
      unsubscribe: null,
      totalRows: null,
      currentPage: 1,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      showValueSetModal: false,
      parent_value_set_id: null,
      vsetCode: null,
      setTimeVsetCode: null,
      company: null,
      billing_period_from_to: [],
      periodFrom: null,
      periodTo: null,
      downloadLog: URL_UTILITY.getDownloadLOGUrl,
      billNature: { text: null, value: null },
      selectedLegalEntity: {
        value: null,
        text: null
      },
      selectedOU: {
        value: null,
        text: null
      },
      selectedBillingCycle: {
        value: null,
        text: null
      },
      selectedFmsProject: {
        value: null,
        text: null
      },
      billType: {
        value: null,
        text: null
      },
      selectedPlan: {
        text: null,
        value: null
      },
      selectedStatus: {
        text: null,
        value: null
      },
      sysBatchName: null,
      batchName: null,
      fiscalYear: null,
      batchBillList: [],
      batchBillFiedls: [
        {
          key: 'selectbox',
          label: 'Select All',
          stickyColumn: true,
          variant: 'primary',
          class: 'stickyColumn'
        },
        {
          key: 'df',
          stickyColumn: true,
          variant: 'primary',
          label: 'Final',
          class: 'stickyColumn'
        },
        {
          key: 'status_meaning',
          label: 'Batch Status'
        },
        {
          key: 'bill_nature'
        },
        {
          key: 'plan_name'
        },
        {
          key: 'period_from'
        },
        {
          key: 'period_to'
        },
        {
          key: 'gl_date',
          label: 'GL Date'
        },
        {
          key: 'bill_date'
        },
        {
          key: 'due_date'
        },
        {
          key: 'project_name',
          label: 'Project'
        },
        // {
        //   key: 'ar_prj_receipt_method_mpng_name',
        //   label:'Receipt Method Name'
        // },
        {
          key: 'lease_prj_name'
        },
        {
          key: 'cycle_name'
        },
        {
          key: 'batch_name'
        },
        {
          key: 'system_batch_name'
        },
        {
          key: 'plan_unit_type_vset_meaning',
          label: 'Project Unit Type'
        },
        {
          key: 'unit_count',
          label: 'Unit count'
        },
        {
          key: 'unit_provisional',
          label: 'Provisional'
        },
        {
          key: 'unit_final',
          label: 'Final'
        },
        {
          key: 'unit_error',
          label: 'Error'
        },
        {
          key: 'request_num'
        },
        {
          key: 'request_date'
        },
        {
          key: 'value_date'
        }
      ],
      updatedGlDate: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showBatchBillModal = true;
        }
        if (
          actionName === 'save' &&
          !this.showBatchBillModal &&
          !this.showBillModal
        ) {
          this.updateBatchDates();
        }
        if (
          actionName === 'download' &&
          !this.showBatchBillModal &&
          !this.showBillModal
        ) {
          this.loader = true;
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'fmsTransaction/getFmsGenBatchList',
            'FMS-Generate-Batch',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getFmsGenBatchBillList() {
      if (
        this.selectedLegalEntity.value ||
        this.selectedOU.value ||
        this.billNature.value ||
        this.selectedBillingCycle.value ||
        this.selectedPlan.value ||
        this.selectedStatus.value ||
        this.sysBatchName ||
        this.selectedFmsProject.value ||
        this.billing_period_from_to[0] ||
        this.billing_period_from_to[1]
      ) {
        this.pyaload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          le_id: this.selectedLegalEntity.value,
          ou_id: this.selectedOU.value,
          bill_nature: this.billNature.value,
          bill_cycle_hdr_id: this.selectedBillingCycle.value,
          billing_plan_hdr_id: this.selectedPlan.value,
          status: this.selectedStatus.value,
          system_batch_name: this.sysBatchName,
          batch_name: this.batchName,
          project_id: this.selectedFmsProject.value,
          period_from: commonHelper.formattedDate(
            this.billing_period_from_to[0]
          ),
          period_to: commonHelper.formattedDate(this.billing_period_from_to[1])
        };
        this.loader = true;
        this.$store
          .dispatch('fmsTransaction/getFmsGenBatchList', this.pyaload)
          .then(response => {
            this.loader = false;
            this.advanceSearch(false);
            if (response.status === 200) {
              this.batchBillList = response.data.data.page.map(data => {
                data.selectbox = false;
                // data.df = true;
                data.df = data.status_meaning === 'PROCESSED' ? true : false;
                data.bill_date = data.bill_date
                  ? new Date(data.bill_date)
                  : null;
                data.due_date = data.due_date ? new Date(data.due_date) : null;
                data.gl_date = data.gl_date ? new Date(data.gl_date) : null;
                data.value_date = data.value_date
                  ? new Date(data.value_date)
                  : null;
                return data;
              });
              this.totalRows = response.data.data.total_elements;
              this.unCheckAllProvisionalBatchBill();
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        alert('Please select atleast one filter.');
      }
    },
    clearSearch() {
      this.selectedOU.text = null;
      this.selectedOU.value = null;
      this.selectedStatus.text = null;
      this.selectedStatus.value = null;
      this.sysBatchName = null;
      this.batchName = null;
      (this.billNature = { text: null, value: null }),
        (this.selectedFmsProject = {
          value: null,
          text: null
        });
      this.approvalStatus = {
        code: null,
        name: null
      };
      this.selectedLegalEntity = {
        value: null,
        text: null
      };
      this.selectedPlan = {
        value: null,
        text: null
      };
      this.selectedBillingCycle = {
        value: null,
        text: null
      };
      this.billing_period_from_to = [];
      this.batchBillList = [];
      this.totalRows = null;
    },
    unCheckAllProvisionalBatchBill() {
      this.batchBillList = this.batchBillList.map(data => {
        if (/provisional/i.test(data.bill_nature)) {
          data.df = false;
        }
        return data;
      });
    },
    updateBatchDates() {
      const filterPayloadData = this.batchBillList.filter(
        data => data.selectbox
      );
      const getPayload = filterPayloadData.map(data => {
        return {
          batch_id: data.bill_batch_id,
          bill_date: data.bill_date
            ? format(new Date(data.bill_date), 'dd-MMM-yyyy')
            : null,
          due_date: data.due_date
            ? format(new Date(data.due_date), 'dd-MMM-yyyy')
            : null,
          gl_date: data.gl_date
            ? format(new Date(data.gl_date), 'dd-MMM-yyyy')
            : null,
          value_date: data.value_date
            ? format(new Date(data.value_date), 'dd-MMM-yyyy')
            : null
        };
      });
      if (getPayload && getPayload.length > 0) {
        const payload = {
          bill_batches: getPayload
        };
        this.loader = true;
        this.$store
          .dispatch('fmsTransaction/updateFmsBatchBillDates', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
              this.batchBillGenerate();
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    batchBillGenerate() {
      const filterPayloadData = this.batchBillList.filter(
        data => data.selectbox
      );
      const getPayload = filterPayloadData.map(data => {
        return {
          batch_id: data.bill_batch_id,
          bill_type: data.df ? 'F' : 'D',
          fms_subunit_id: null
        };
      });
      if (getPayload && getPayload.length > 0) {
        const payload = {
          batches: getPayload
        };
        this.loader = true;
        this.$store
          .dispatch('fmsTransaction/saveBatchBill', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 201) {
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    rowSelected(item) {
      this.selectedBatch = item;
      this.showBillModal = true;
    },
    showhideBatchBillModal(flag) {
      this.showBatchBillModal = flag;
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      if (
        this.vsetCode === appStrings.VALUESETTYPE.OU_LIST ||
        this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT ||
        this.vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE
      ) {
        this.parent_value_set_id = this.selectedLegalEntity.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILLING_PLAN) {
        this.parent_value_set_id = this.selectedBillingCycle.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.text = item.org_name;
        this.selectedLegalEntity.value = item.org_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.selectedOU.text = item.org_name;
        this.selectedOU.value = item.org_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.selectedBillingCycle.text = item.billing_cycle_name;
        this.selectedBillingCycle.value = item.billing_cycle_hdr_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.selectedFmsProject.text = item.fms_prj_name;
        this.selectedFmsProject.value = item.proj_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILLING_PLAN) {
        this.selectedPlan.text = item.billing_plan_name;
        this.selectedPlan.value = item.billing_plan_hdr_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_STATUS) {
        this.selectedStatus.text = item.value_meaning;
        this.selectedStatus.value = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_NATURE) {
        this.billNature = {
          text: item.value_meaning,
          value: item.value_code
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.selectedLegalEntity.text = null;
        this.selectedLegalEntity.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.selectedOU.text = null;
        this.selectedOU.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.BILLING_CYCLE) {
        this.selectedBillingCycle.text = null;
        this.selectedBillingCycle.value = null;
        this.selectedPlan.text = null;
        this.selectedPlan.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.FMS_PROJECT) {
        this.selectedFmsProject.text = null;
        this.selectedFmsProject.value = null;
      } else if (vsetCode === appStrings.VALUESETTYPE.BILLING_PLAN) {
        this.selectedPlan.text = null;
        this.selectedPlan.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_STATUS) {
        this.selectedStatus.text = null;
        this.selectedStatus.value = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BILL_NATURE) {
        this.billNature = {
          text: null,
          value: null
        };
      }
    },
    advanceSearch(flag) {
      this.showAdvanceSearch = flag;
    },
     // checkUncheckAll() { /////////////////////////////////////Not used previous function
    //   this.batchBillList = this.batchBillList.map(data => {
    //     if (data.request_num) {
    //       if (!/error/i.test(data.status)) {
    //         data.selectbox = false;
    //       } else {
    //         data.selectbox = this.selectAllCheckBox;
    //       }
    //     } else {
    //       data.selectbox = this.selectAllCheckBox;
    //     }
    //     return data;
    //   });
    // },
    checkUncheckAll() {
      this.batchBillList = this.batchBillList.map((data, index) => {
        if (data.request_num) {
          if (!/error/i.test(data.status)) {
            data.selectbox = false;
          } else {
            data.selectbox = this.selectAllCheckBox;
          }
        } else {
          data.selectbox = this.selectAllCheckBox;
        }
        if (this.selectAllCheckBox) {
          this.selectBoxChecked(data.selectbox, index);
        }
        return data;
      });
    },
    selectBoxChecked(flag, index) {
      if (this.batchBillList[index].status !== 'P') {
        this.batchBillList[index].selectbox = flag;
        if (flag) {
          this.batchBillList[index].bill_date = this.batchBillList[index].bill_date
            ? this.batchBillList[index].bill_date
            : this.batchBillList[index].period_from;
          const glDatePromiseResult = commonHelper.getModuleBasedPeriodDate(
            22,
            this.batchBillList[index].le_id,
            this.batchBillList[index].ou_id
          );
          glDatePromiseResult.then(defaultOpenPeriodDate => {
            this.updatedGlDate = defaultOpenPeriodDate;
            this.validateGlDate(index, defaultOpenPeriodDate);
          });
        }
      }
    },
    validateGlDate(index, defaultOpenPeriodDate) {
      this.batchBillList[index].gl_date = defaultOpenPeriodDate;
      const validatePeriodDatePromise = commonHelper.getValidatePeriodDate(
        this.batchBillList[index].gl_date,
        22,
        this.batchBillList[index].le_id,
        this.batchBillList[index].ou_id
      );
      validatePeriodDatePromise.then(isPeriodOpen => {
        this.batchBillList[index].gl_date =
          isPeriodOpen === 'NOTDEFINED'
            ? null
            : new Date(defaultOpenPeriodDate);
        this.batchBillList[index].bill_date =
          isPeriodOpen === 'NOTDEFINED'
            ? null
            : new Date(defaultOpenPeriodDate);
      });
      this.generateDueDate(index);
      // }
      // this.updateTenantInvoiceData(index);
    },
    showhideBillModal(flag) {
      this.showBillModal = flag;
    },
    validateCheckBox(batchRow) {
      if (batchRow.request_num) {
        if (!/e/i.test(batchRow.status)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    generateDueDate(index) {
      const payload = {
        payment_term_id: this.batchBillList[index].payment_term_id,
        invoice_date: this.updatedGlDate,
        gl_date: this.updatedGlDate
      };
      this.loader = true;
      this.$store
        .dispatch('fmsTransaction/updateFmsDueDate', payload)
        .then(response => {
          if (response.status === 200) {
            this.loader = false;
            this.batchBillList[index].due_date = new Date(response.data.data);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
